<template>
  <div class="header global">
    <div class="grid-container full white">
      <div
        class="main-content"
        :class="{'canvas-collapse': collapse}"
      >
        <breadcrumb :items="items"/>
        <div class="header-wrapper grid-x align-justify">
          <h1 class="heading heading-1">{{ $route.name }}</h1>
          <div class="general-actions grid-x">
            <div class="action-item">
              <a
                class="button-badge"
                href="javascript:void(0);"
                @click="toggleNotifications"
              >
                <bell-outline aria-selected="false" :class="{ 'active': toogleMenu }" class="bell"/>
                <span v-show="getNews" class="badge alert">{{ getNews }}</span>
              </a>
            </div>
            <!-- <div class="action-item">
              <img :src="require('@/assets/images/configuration-icon.svg')" alt="Configuration">
            </div> -->
            <div class="action-item">
              <div class="sidebar-profile">
                <div class="profile-photo">
                  <span class="profile-acronym">{{ profileAcronym }}</span>
                </div>
                <div class="profile-name">{{ profileName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import jwtDecode from 'jwt-decode';
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumb: {
        dashboard: [{
          text: this.$t('app.dashboard'),
          disabled: false,
          href: '/dashboard',
        }],
        templates: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.sendTemplates'),
            disabled: false,
            href: '/send-templates',
          },
        ],
        notifications: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: 'dashboard',
          },
          {
            text: this.$t('app.notifications'),
            disabled: false,
            href: 'notifications',
          },
        ],
        campaigns: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.campaigns'),
            disabled: false,
            href: '/campaigns',
          },
        ],
        campaignSettings: [
          {
            text: this.$t('app.dashboard'),
            disabled: true,
            href: '/dashboard',
          },
          {
            text: this.$t('app.campaigns'),
            disabled: true,
            href: '/campaigns',
          },
          {
            text: this.$t('app.campaign-settings'),
            disabled: false,
            href: '',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      collapse: state => state.dashboard.collapse,
      toogleMenu: state => state.notification.toogleMenu,
    }),
    ...mapGetters({
      accessToken: 'dashboard/getAccessToken',
      getNews: 'notification/getNews',
    }),
    decodedData() {
      let decoded;

      try {
        decoded = jwtDecode(this.accessToken);
      } catch (e) {
        decoded = {};
      }

      return decoded;
    },
    profileName() {
      return this.decodedData && this.decodedData.name ? this.decodedData.name : '???';
    },
    profileAcronym() {
      return this.profileName.substr(0, 1);
    },
    items() {
      if (this.$route.path === '/dashboard') {
        return this.breadcrumb.dashboard;
      }
      if (this.$route.path === '/send-templates') {
        return this.breadcrumb.templates;
      }
      if (this.$route.path === '/notifications') {
        return this.breadcrumb.notifications;
      }
      if (this.$route.path === '/campaigns') {
        return this.breadcrumb.campaigns;
      }
      if (this.$route.path.includes('/settings')) {
        return this.breadcrumb.campaignSettings;
      }
      return [];
    },
  },
  methods: {
    toggleNotifications() {
      this.$emit('open-notifications');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_header.scss';
</style>
